.layout {
  &.web {
    .header {
      height: auto;
      background: @white;
      padding: 0;
      box-shadow: @header-box-shadow;

      .top-bar {
        padding: 20px 70px;

        .logo {
          float: left;

          .text-logo-rgb {
            font-size: 35px;
            line-height: 35px;
            font-weight: 400;
          }
        }

        .actions,
        .logged-user {
          float: right;
        }

        .logged-user {
          line-height: 22px;
          height: @layout-header-height;
          text-align: right;
          padding: 11px 0;

          .logged-user-info {
            font-size: 1.1em;
            font-weight: 600;
          }

          .logged-user-actions {
            button {
              padding: 0;
              text-transform: uppercase;
              font-size: 0.8em;
              height: auto;
            }
          }
        }
      }

      .nav-bar {
        .ant-menu {
          padding: 0 50px;
          background-image: linear-gradient(to left, @menu-dark-bg, lighten(@menu-dark-bg, 5));

          .ant-menu-item {
            &.ant-menu-item-selected {
              background: transparent;
            }
          }
        }
      }

      &.responsive {
        height: @layout-header-height;
        line-height: @layout-header-height;

        .trigger {
          font-size: 18px;
          cursor: pointer;
          transition: color 0.3s;
          color: @menu-dark-bg;
          line-height: @layout-header-height;
          padding: @layout-trigger-padding;
          height: @layout-header-height;
          width: @layout-header-height;

          &.trigger-menu {
            float: left;
          }

          &.trigger-more {
            float: right;
          }

          &:hover {
            color: @primary-color;
          }
        }

        .login-or-register {
          text-align: right;
          padding: 0 20px;
        }

        .title {
          text-align: center;
          width: 100%;
          display: block;
          font-size: 1.5em;
        }
      }
    }

    .content {
      padding: 5px;
    }

    @media screen and (min-width: @screen-lg + 1px) {
      .content {
        padding: 15px;
      }
    }

    .footer {
      font-size: 0.82em;
      flex-direction: row;
      justify-content: center;
      display: flex;
      padding: 20px 30px;

      .anticon {
        font-size: 10px;
        color: @error-color;
        vertical-align: 0;
        margin: 0 3px;
      }

      .separator {
        margin: 0 10px;
      }
    }
  }
}
