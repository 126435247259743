.ant-result {
  height: 100vh;
  padding-top: 20vh;

  &.ant-result-404 {
    .ant-result-icon {
      margin-bottom: 40px;
      text-align: center;
    }

    .ant-result-title {
      color: rgb(151 215 0);
      font-size: 24px;
      line-height: 34px;
      text-align: center;
      font-weight: 700;
    }

    .ant-result-subtitle {
      color: #646464;
      font-size: 16px;
      line-height: 16px;
      text-align: center;
    }

    .ant-result-extra {
      margin: 40px 0 0 0;
      text-align: center;

      .ant-btn {
        font-weight: 700;
        text-transform: uppercase;
      }
    }

    svg {
      path:nth-child(53) {
        fill: darken(@primary-color, 10);
      }

      path:nth-child(11), path:nth-child(54) {
        fill: @primary-color;
      }
    }
  }
}
