@import (css) url('https://fonts.googleapis.com/css2?family=Quicksand:wght@400;500;600;700&display=swap');
@import '~antd/dist/antd.less';

@import 'common/_common';
@import 'components/_components';
@import 'containers/_containers';
@import 'layouts/_layouts';

* {
  font-family: 'Quicksand', sans-serif;
}
