.ant-table.ant-table-bordered > .ant-table-container {
  border: 1px solid @table-header-border-color;
}

.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th:not(.ant-table-cell-scrollbar),
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > thead > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > th,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-content > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > tfoot > tr > td,
.ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tfoot > tr > td {
  border-right: 1px solid @table-header-border-color;
}

.ant-table {
  .react-resizable {
    position: relative;
    background-clip: padding-box;
  }

  .ant-table-thead {
    .ant-table-cell {
      border-bottom: 3px solid @table-header-border-color;

      .react-resizable-handle {
        position: absolute;
        width: 10px;
        height: 100%;
        bottom: 0;
        right: -5px;
        cursor: col-resize;
        z-index: 1;
      }

      .ant-table-filter-column {
        height: auto;

        .ant-table-column-sorter {
          margin: 0 0 0 15px;
        }
      }
    }

    tr > th {
      text-align: left !important;
    }
  }

  .ant-table-body {
    .ant-table-row:last-child {
      td {
        border-bottom: 0;
      }
    }
  }
}

.ant-table-cell {
  white-space: nowrap;
}

.ant-table-filter-dropdown > div {
  & > div:first-child,
  input {
    width: 188px;
    margin-bottom: 8px;
    display: block;
  }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  padding: 5px 10px;
}

.grid-toolbar {
  background-color: @table-header-bg;
  border: 1px solid @table-header-border-color;
  border-bottom-width: 2px;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  .ant-btn {
    background: transparent;
    border: 0;
    height: 40px;
    font-size: 0.8em;
    letter-spacing: 0.5px;

    &:hover {
      background: darken(@table-header-bg, 5);
      color: inherit;
    }

    &:focus {
      background: transparent;
      color: inherit;
    }
  }
}

.column-item {
  display: flex;

  .drag-handler {
    cursor: move;

    svg {
      color: #000;
      position: relative;
      top: 2px;
    }
  }
}

