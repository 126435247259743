// Fix menu anticon
.ant-menu-item,
.ant-menu-submenu {
  .anticon {
    vertical-align: 0;

    .icon-content {
      margin-left: 5px;
    }
  }
}

.ant-drawer-wrapper-body,
.ant-layout-sider {
  .ant-menu-item,
  .ant-menu-submenu {
    .anticon {
      width: 35px;
      padding: 0;
      margin: 0;
      text-align: left;
    }
  }

  .ant-menu-sub {
    background: #0000001a !important;
    padding: 5px 10px;
    -webkit-box-shadow: inset 0px 1px 20px 2px rgba(0, 0, 0, 0.08);
    -moz-box-shadow: inset 0px 1px 20px 2px rgba(0, 0, 0, 0.08);
    box-shadow: inset 0px 1px 20px 2px rgba(0, 0, 0, 0.08);
  }
}

.anticon {
  vertical-align: 0;

  .icon-content {
    margin: 0 5px;
  }
}
