.layout {
  &.auth {
    //background-image: linear-gradient(to bottom right, darken(@menu-dark-bg, 5), lighten(@menu-dark-bg, 5));
    background-color: white;

    .header {
      background: transparent;
      height: auto;

      .logo {
        text-align: center;
        margin: 5% 0 10px 0;

        .text-logo {
          font-size: 25px;
          font-weight: 400;
        }
      }
    }

    .content {
      margin: 0 20px 100px 20px;

      .ant-card {
        margin: 0 auto;

        .ant-card-body {
          .card-spin {
            width: 100%;
            text-align: center;
            padding: 5%;
          }
        }
      }
    }
  }
}
