.connection-status-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .connection-status-icon {
    margin-right: 5px;
  }

  &.margin {
    margin-right: 20px;
  }

  &.on-grid {
    justify-content: center;
  }

  .connection-status-icon {
    //width: 12px;
    //height: 12px;
    //display: block;
    //margin-right: 8px;
    //border-radius: 180px;
    color: @error-color;

    &.is-connected {
      color: @success-color;
    }

    &.working {
      color: @warning-color;
    }
  }

  .connection-status-text {
    font-weight: bold;
    text-transform: uppercase;
  }
}

.dragger-container {
  display: block;
}

.upload-button-container {
  margin-top: 20px;
}

.upload-progress-container {
  text-align: center;

  .anticon {
    font-size: 60px;
    margin-bottom: 15px;
  }

  .ant-spin-text {
    font-weight: bold;
    font-size: 16px;
  }

  .spinner {
    &.checking {
      color: gray;

      & + .ant-spin-text {
        color: gray;
      }
    }

    &.in-progress {
      color: #4990d1;

      & + .ant-spin-text {
        color: #4990d1;
      }
    }

    &.success {
      color: @primary-color;

      & + .ant-spin-text {
        color: @primary-color;
      }
    }
  }

  &.failed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .anticon {
      color: @error-color;
    }

    .text {
      color: @error-color;
      font-weight: bold;
      font-size: 16px;
    }
  }

  &.success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .anticon {
      color: @success-color;
    }

    .text {
      color: @success-color;
      font-weight: bold;
      font-size: 16px;
    }
  }
}

.upload-progress-result-btn {
  text-align: center;
  margin-top: 20px;
}

.stats {
  .ant-tag {
    .anticon {
      margin-right: 7px;
    }
  }
}

.water-warning {
  color: #4990d1;
  font-weight: bold;
}
