@import '_variables';

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background: #c5cbd3;
  border-radius: 1px;
}

::-webkit-scrollbar-thumb:hover {
  background: #959ca6;
}

::-webkit-scrollbar-track {
  background: #eee;
  border-radius: 1px;
}

.ant-input-number {
  width: 100%;
}


.notify-colored {
  .anticon,
  .ant-notification-notice-message,
  .ant-notification-notice-description {
    color: #fff;
  }

  &.success {
    background-color: @success-color;
  }

  &.error {
    background-color: @error-color;
  }

  &.warning {
    background-color: @warning-color;
  }
}

.ant-picker {
  width: 100%;
}
