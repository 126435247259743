.box-container {
  overflow-x: auto;
}

.box-row {
  display: flex;

  .compartment {
    width: 150px !important;
    height: 37px !important;
    margin: 3px !important;
    border: 0;
    color: white;
    font-size: 1rem;
    border-radius: 3px;

    button {
      width: 150px !important;
      height: 37px !important;
      margin: 0 !important;
      border: 0;
      color: white;
      font-size: 1rem;
      border-radius: 3px;
    }

    &.door-closed {
      background-color: @success-color;

      &:hover {
        background-color: darken(@success-color, 5);
        color: white;
      }

      &[disabled] {
        background-color: #e5e7ea !important;
      }
    }

    &.door-opened, &.door-opened:hover {
      background-color: @warning-color !important;
      color: white;

      button {
        background-color: @warning-color !important;
        color: white;
      }
    }

    &.door-long-opened, &.door-long-opened:hover {
      background-color: @error-color !important;
      color: white;

      button {
        background-color: @error-color !important;
        color: white;
      }
    }

    &.placeholder, &.placeholder:hover {
      background-color: #e5e7ea !important;

      button {
        background-color: #e5e7ea !important;
      }
    }
  }

  .box-container {
    .ant-card-actions {
      padding: 0 20px;

      li {
        text-align: left;
      }
    }
  }
}
