.ant-card {
  box-shadow: @card-shadow;
  margin: 5px;

  .ant-card-head-title {
    .anticon {
      .icon-content {
        margin-left: 15px;
      }
    }
  }
}

@media screen and (min-width: @screen-lg + 1px) {
  .ant-card {
    box-shadow: @card-shadow;
    margin: 15px;
  }
}
