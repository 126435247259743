@import '_auth';
@import '_web';
@import '_empty';

html {
  min-height: 100%;
  height: auto;
}

body,
#root,
.layout,
.ant-layout {
  min-height: 100vh !important;
}

.text-logo {
  text-transform: uppercase;
  font-weight: 400;
  color: @white;

  span {
    color: @primary-color;
  }
}

.text-logo-rgb {
  font-weight: 700;
  text-transform: uppercase;
  color: @menu-dark-bg;

  span {
    color: @primary-color;
  }
}

.ant-drawer-wrapper-body {
  background-image: none;

  .ant-drawer-footer {
    text-align: right;

    button {
      margin-left: 8px;
    }
  }
}

.ant-form-item {
  margin-bottom: 15px;
}


.drawer-responsive {
  .ant-drawer-header {
    height: @layout-header-height;
    background: transparent;
    border: 0;

    .ant-drawer-title,
    .ant-drawer-close {
      color: @white;
    }

    .text-logo-rgb {
      color: @white;
    }
  }

  .ant-drawer-wrapper-body {
    background-image: @sider-background-image;
  }

  .ant-drawer-body {
    padding: 0;
    height: calc(100% - @layout-header-height) !important;
    overflow-x: hidden;
    overflow-y: auto;

    .ant-menu {
      background: transparent;
      border-right: 0;

      .ant-menu-item-divider {
        background-color: #5f4256;
        margin: 0 30px 0 20px;
        box-shadow: 0px 1px 20px 2px rgba(0, 0, 0, 0.08);
      }

      .ant-menu-item {
        color: rgba(255, 255, 255, 0.65);

        &:hover {
          color: @white;
        }

        &.ant-menu-item-selected {
          background-color: transparent;

          &::after {
            border-right: 0;
          }
        }
      }
    }
  }
}
